import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  htmlText: {
    fontSize: "1.75em",
    marginBottom: "60px",
    maxWidth: "50vw",
    fontStyle: "italic",
    color: "#000",
    lineHeight: "1.35",
  },
  upNext: {
    color: "#000",
    fontStyle: "italic",
    marginBottom: "0.5vh",
    marginTop: "-1vh",
  },
  title: {
    color: "#2A7050",
    margin: "0px 0px 35px",
    fontSize: "xxx-large",
  },
  outterDiv: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  imageDiv: {
    marginTop: "-125px",
  },
});

const ClosingSlide = ({ text, image, title }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        width: "81vw",
        height: "74%",
      }}
    >
      <h3 className={classes.upNext}>&nbsp;</h3>
      <p className={classes.title}>&nbsp;</p>

      <Grid container>
        <Grid item xs={8}>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className={classes.htmlText}
          />
        </Grid>
        <Grid
          item
          style={{ display: "flex", justifyContent: "center" }}
          xs={4}
          className={classes.imageDiv}
        >
          <img src={image} alt="closing" height="500px" />
        </Grid>
      </Grid>
    </div>
  );
};

ClosingSlide.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  modules: PropTypes.array,
  setRightButtonText: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ClosingSlide;
