import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import { PulseLoader } from "react-spinners";

const useStyles = makeStyles({
  buttons: {
    bottom: "40px",
    left: "28px",
    position: "absolute",
    color: "#b7b7b7",
    zIndex: "1",
  },
});

const playFunction = () => {
  var video = document.getElementById("myVideo");

  // Get the button
  var btn = document.getElementById("playBtn");
  if (video.paused) {
    video.play();
    video.style.display = "block";

    btn.innerHTML = "Pause";
  } else {
    video.pause();
    btn.innerHTML = "Start";
  }
};

const backFunction = () => {
  var video = document.getElementById("myVideo");

  video.currentTime = video.currentTime - 10;
};

const forwardFunction = () => {
  var video = document.getElementById("myVideo");

  video.currentTime = video.currentTime + 10;
};

const VideoSlide = ({ videoUrl, isLoading }) => {
  const classes = useStyles();
  // const [isLoading, setIsLoading] = useState(true);
  // console.log("isloading", isLoading);

  return isLoading ? (
    <PulseLoader
      sizeUnit="px"
      size={15}
      color="#60A681"
      loading
      speedMultiplier={".5"}
    />
  ) : (
    <>
      <video
        id="myVideo"
        style={{
          position: "fixed",
          bottom: "10px",
          maxWidth: "90%",
          maxHeight: "90%",
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div className={classes.buttons}>
        <Button
          // className={classes.back}
          style={{ color: "#b7b7b7" }}
          onClick={() => backFunction()}
          id="skipBackdBtn"
        >
          {<SkipPreviousIcon />}{" "}
        </Button>
        <Button
          // className={classes.button}
          onClick={() => playFunction()}
          style={{ color: "#b7b7b7" }}
          id="playBtn"
        >
          Start
        </Button>
        <Button
          // className={classes.forward}
          style={{ color: "#b7b7b7" }}
          onClick={() => forwardFunction()}
          id="skipForwardBtn"
        >
          {<SkipNextIcon />}
        </Button>
      </div>

      {/* </div> */}
    </>
  );
};

VideoSlide.propTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
};

export default VideoSlide;
