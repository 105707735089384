/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { loadAuthToken } from "../../../../utils/auth";
import { AuthContext } from "../../../../Auth";
import BarGraph from "../../../../assests/img/barGraph.png";
import { PulseLoader } from "react-spinners";

const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
    // marginTop: "65px",
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: "2rem",
  },
  contentDiv: {
    paddingTop: "1rem",
  },
  text: {
    fontSize: "1.75rem",
    color: "#000",
  },
  smallText: {
    fontSize: "1.4rem",
    color: "#000",
  },
  title: {
    fontSize: "xxx-large",
    color: "#2A7050",
  },
  messageIcon: {
    color: "#6CCA98",
    height: "100px",
    width: "100px",
  },
  image: {
    width: "315px",
  },
  number: {
    fontSize: "35px",
    color: "#60A681",
    marginTop: "50px",
  },
});

const Poll = ({ moduleTitle, sessionId, moduleId, channel, isLoading }) => {
  const context = useContext(AuthContext);
  const [usersAnswered, setUsersAnswered] = useState(0);

  const getFinishedUsers = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/poll-answer?sessionId=${sessionId}&moduleId=${moduleId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    })
      .then((res) => {
        if (
          res.data === "your token is not valid" ||
          res.data === "Your token is expired"
        ) {
          context.sendToLogin();
        }
        setUsersAnswered(res.data.usersAnswered);
      })
      .catch((err) => {
        if (
          err.error === "your token is not valid" ||
          err.error === "Your token is expired"
        ) {
          context.sendToLogin();
        }
      });
  };

  useEffect(() => {
    getFinishedUsers();
    const binds = [];
    if (channel) {
      channel.bind(`poll_completion_${sessionId}_${moduleId}`, (completed) => {
        setUsersAnswered(completed);
      });

      return () => {
        binds.forEach((bind) => {
          channel.unbind(bind);
        });
        channel.unbind(`poll_completion_${sessionId}_${moduleId}`);
      };
    }
  }, [sessionId, moduleId, channel]);

  const classes = useStyles();
  return isLoading ? (
    <PulseLoader
      sizeUnit="px"
      size={15}
      color="#60A681"
      loading
      speedMultiplier={".5"}
    />
  ) : (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img src={BarGraph} className={classes.image} alt="poll" />
      </div>
      <div className={classes.contentDiv}>
        <p className={classes.title}>
          <strong>{moduleTitle}</strong>
        </p>
        <br />
        <p className={classes.text}>Please respond to each question</p>
        <br />
        <p className={classes.number}>
          <strong> {usersAnswered ? String(usersAnswered) : "0"}</strong>
        </p>
        <p className={classes.smallText}>Completions</p>
      </div>
    </div>
  );
};

Poll.propTypes = {
  moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Poll;
