import React from "react";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";

const BarGraph = (props) => {
  const { answers } = props;
  // console.log("props", props);

  let myLabels = [];
  let myColors = [
    "#0EB79D",
    "#AE3C82",
    "#AACE8C",
    "#14658B",
    "#DA9E89",
    "#4AB0AD",
    "#FFAD18",
    "#E4C2B2",
    "#42A231",
    "#C4580B",
  ];

  const myData = [];

  answers.map((x) => myLabels.push(Object.keys(x)[0]));
  answers.map((x) => myData.push(Object.values(x)[0]));

  const data = {
    labels: myLabels,
    datasets: [
      {
        label: "Dataset",
        backgroundColor: myColors,
        borderColor: "rgb(255, 99, 132)",
        data: myData,
      },
    ],
  };

  const options = {
    responsive: true,
    parsing: {
      xAxisKey: "option",
      yAxisKey: "count",
    },
    tooltips: {
      mode: "label",
    },
    plugins: {
      labels: false,
    },
    legend: {
      display: false,
      labels: {
        fontSize: 25,
        fontFamily: '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            // suggestedMax: 10,
            stepSize: 1,
            fontSize: 25,
            fontFamily:
              '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
          },
        },
      ],
      xAxes: [
        {
          display: false,
          ticks: {
            fontSize: 25,
          },
        },
      ],
    },
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <h4
          style={{ transform: "rotate(-90deg)", height: 0, marginTop: "20%" }}
        >
          Number of Participants
        </h4>
        <Bar
          options={options}
          data={data}
          {...props}
          width={"20vw"}
          height={"10vh"}
        />
      </div>

      <h4 style={{ paddingLeft: "28vw" }}>Responses</h4>
    </div>
  );
};

BarGraph.propTypes = {
  answers: PropTypes.array.isRequired,
};

export default BarGraph;
