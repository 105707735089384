/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CountUp from "react-countup";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  text: {
    fontSize: "1.4rem",
    color: "#000",
  },
  messageIcon: {
    color: "#6CCA98",
    height: "100px",
    width: "100px",
  },
  completions: {
    fontSize: "1.4rem",
    marginTop: "50px",
  },
  number: {
    color: "#60A681",
    fontSize: "35px",
  },
});

function WordCloudWelcome({
  sessionId,
  moduleId,
  channel,
  wordCloudQuestions,
  totalCompleted,
  setTotalCompleted,
  isDemo,
}) {
  const [startingAnswerCount, setStartingAnswerCount] = useState([]);
  const [answerCount, setAnswerCount] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const binds = [];
    if (channel) {
      channel.bind(`cloud_completion_${sessionId}_${moduleId}`, (completed) => {
        setTotalCompleted(completed);
      });
      wordCloudQuestions.forEach((question, index) => {
        binds.push(`cloud_question_${question.id}_${sessionId}_${moduleId}`);
        channel.bind(
          `cloud_question_${question.id}_${sessionId}_${moduleId}`,
          (count) => {
            const answerCountCopy = [...answerCount];
            const startingAnswerCountCopy = [...startingAnswerCount];
            startingAnswerCountCopy[index] = Number(answerCountCopy[index]);
            answerCountCopy[index] = count;
            isDemo
              ? setStartingAnswerCount([135, 135])
              : setStartingAnswerCount(startingAnswerCountCopy);
            isDemo
              ? setAnswerCount([146, 148])
              : setAnswerCount(answerCountCopy);
          }
        );
      });
      return () => {
        binds.forEach((bind) => {
          channel.unbind(bind);
        });
        channel.unbind(`cloud_completion_${sessionId}_${moduleId}`);
      };
    }
  }, [wordCloudQuestions, answerCount, channel, sessionId, moduleId]);

  useEffect(() => {
    const array = [...wordCloudQuestions.map((q) => q.count)];
    isDemo ? setAnswerCount([186, 203]) : setAnswerCount([...array]);
    isDemo
      ? setStartingAnswerCount([175, 195])
      : setStartingAnswerCount([...array]);
  }, [wordCloudQuestions]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {wordCloudQuestions.map((question, index) => (
          <React.Fragment key={question.id + 2500}>
            <p className={classes.text}>
              {answerCount[index] ? (
                <CountUp
                  start={startingAnswerCount[index]}
                  end={answerCount[index]}
                  className={classes.number}
                />
              ) : (
                <span className={classes.number}>0</span>
              )}
              <br />
              {question.description}{" "}
            </p>
            {/* {index + 1 !== wordCloudQuestions.length ? (
              <Divider flexItem orientation="vertical" />
            ) : null} */}
          </React.Fragment>
        ))}
      </div>
      <p className={classes.text} styles={{ marginTop: "50px" }}>
        <span className={classes.number}>{isDemo ? 48 : totalCompleted}</span>
        <br />
        Completions
      </p>
    </div>
  );
}

WordCloudWelcome.propTypes = {
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  wordCloudQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCompleted: PropTypes.number,
  setTotalCompleted: PropTypes.func,
};

export default WordCloudWelcome;
