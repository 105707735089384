import React, { useRef, useState } from 'react';
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import chatImage from "../../../../assests/img/chatImage2.png";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

const useStyles = makeStyles({
  discussionQuestion: {
    fontSize: "1.75em",
    marginBottom: "60px",
    maxWidth: "50vw",
    fontStyle: "italic",
    color: "#000",
    lineHeight: "1.35",
    zIndex: 1,
    paddingRight: "20px",
  },
  upNext: {
    color: "#000",
    fontStyle: "italic",
    marginBottom: "-2.5vh",
    marginTop: "-1vh",
  },
  title: {
    color: "#2A7050",
    margin: "0px 0px 35px",
    fontSize: "xxx-large",
    position: "relative",
  },
  arrow: {
    fontSize: "45px",
    // marginBottom: "-10",
  },
  container: {
    marginTop: "20px",
  },
  outterDiv: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  imageDiv: {
    margin: "-125px 0 0 -94px",
  },
  copyButton: {
    margin: '25px 0px 7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    height: '47px',
    backgroundColor: '#60A681',
    cursor: 'pointer',
    padding: '10px 25px',
  }
});

const DiscussionSlide = ({ title, image, questions }) => {
  const classes = useStyles();
  const [btnText, setBtnText] = useState('Copy Questions');

  const textAreaRef = useRef(null);

  const copyURL =() => {
    navigator.clipboard.writeText(textAreaRef.current.innerText)
    setBtnText('Copied!');
    setTimeout(() => { 
      setBtnText('Copy Questions'); 
  }, 1000)
}

  return (
    <div style={{ width: "81vw" }}>
      <div>
        <h3 className={classes.upNext}>Up next</h3>
        <p className={classes.title}>
          {title}{" "}
          <ArrowForwardRoundedIcon
            className={classes.arrow}
            style={{ marginBottom: "-10" }}
          />
          <img height="82px" src={chatImage} alt="chat icon" />
        </p>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={8}>
          <div className={classes.outterDiv}                   ref={textAreaRef}>
          {/* <textarea
          ref={textAreaRef}
          value='Some text to copy'
        /> */}
            {questions.map((question) => {
              return (
                <p
                  key={question.id}
                  dangerouslySetInnerHTML={{
                    __html: question.slide_question.questionText,
                  }}
                  className={classes.discussionQuestion}
                />
                // <p key={question.id} className={classes.discussionQuestion}>
                //   {question.slide_question.questionText}
                // </p>
              );
            })}
          </div>
          <div>
          <Button
            className={classes.copyButton}

            onClick={() => 
              copyURL()
            }>
            {btnText}
          </Button>
        </div>
        </Grid>
        <Grid item xs={4} className={classes.imageDiv}>
          <img src={image} alt="discussion" height="500px" />
        </Grid>
      </Grid>
    </div>
  );
};

DiscussionSlide.propTypes = {
  image: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object),
};

export default DiscussionSlide;
